import { ReactNode } from 'react';
import {
  Box,
  Stack,
  HStack,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  List,
  ListItem,
  ListIcon,
} from '@chakra-ui/react';
import { FaCheckCircle } from 'react-icons/fa';
import { PRODUCT_PAGE_URL } from 'lib/constants';
import { ActionButton } from 'components/ActionButton';
import { HomeSection } from 'components/HomeSection';

function PriceWrapper({ children }: { children: ReactNode }) {
  return (
    <Box
      mt={4}
      shadow="base"
      borderWidth="1px"
      alignSelf={{ base: 'center', lg: 'flex-start' }}
      borderColor={useColorModeValue('gray.200', 'gray.500')}
      borderRadius={'xl'}
      bg={'white'}
    >
      {children}
    </Box>
  );
}

export function Pricing() {
  return (
    <HomeSection id={'section-pricing'}>
      <VStack spacing={2} textAlign="center">
        <Heading as="h1" fontSize="4xl">
          Get the Early Release
          {/* 🛍 🎁 Black Friday Special! PAY WHAT YOU WANT. 🎁 🛍 */}
        </Heading>
        <Text fontSize="lg" color={'gray.500'}>
          Over 170 pages of lessons and examples for an incredible price. Free
          updates as the book grows.
        </Text>
      </VStack>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        textAlign="center"
        justify="center"
        spacing={{ base: 4, lg: 10 }}
        pt={10}
      >
        <PriceWrapper>
          <Box position="relative">
            <Box
              position="absolute"
              top="-16px"
              left="50%"
              style={{ transform: 'translate(-50%)' }}
            >
              <Text
                textTransform="uppercase"
                bg={'#f0d8b4'}
                px={3}
                py={1}
                color={'accent'}
                fontSize="sm"
                fontWeight="600"
                rounded="xl"
              >
                Beta book
              </Text>
              {/* Limited Time Price */}
            </Box>
            <Box pt={10} pb={6} px={12}>
              <Text fontWeight="500" fontSize="2xl">
                Current release
              </Text>
              <HStack justifyContent="center" marginLeft="-50px">
                <Text
                  fontSize="xl"
                  color="gray.400"
                  textDecoration="line-through"
                >
                  $30
                </Text>
                <Text fontSize="3xl" fontWeight="bold">
                  $10
                </Text>
              </HStack>
            </Box>
            <VStack
              borderTopWidth={1}
              borderTopColor={'lightBeige'}
              py={4}
              borderBottomRadius={'xl'}
            >
              <List pt={2} spacing={3} textAlign="start" px={12}>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Over 170 pages of content
                </ListItem>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Full source code
                </ListItem>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Free updates
                </ListItem>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Dozens of lessons
                </ListItem>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Free access to JamShots photo app when it&#39;s live
                </ListItem>
              </List>
              <Box w="80%" pt={7}>
                <ActionButton w="full" href={PRODUCT_PAGE_URL} target="_blank">
                  Buy Now
                </ActionButton>
              </Box>
            </VStack>
          </Box>
        </PriceWrapper>
      </Stack>
    </HomeSection>
  );
}
