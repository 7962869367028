import { ReactNode } from 'react';
import { ContainerProps, Container } from '@chakra-ui/react';

export type MaxWidthContainerProps = ContainerProps & Props;

export interface Props {
  children: ReactNode;
}
export const MaxWidthContainer = ({
  children,
  ...rest
}: MaxWidthContainerProps) => (
  <Container maxW="5xl" w="100%" {...rest}>
    {children}
  </Container>
);
