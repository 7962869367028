import { ReactNode } from 'react';
import { Box, Heading, SimpleGrid } from '@chakra-ui/react';
import { HomeSection } from 'components/HomeSection';
import { NextAuthLogo } from 'svgs/NextAuthLogo';
import Image from 'next/legacy/image';
export const LOGO_SECTION_HEIGHT = 100;

export type LogoImageProps = {
  src?: string;
  width: number;
  height: number;
  children?: ReactNode;
  objectFit?: 'contain' | 'cover';
};

export const LogoImage = ({
  src,
  objectFit = 'contain',
  children,
}: LogoImageProps) => (
  <Box
    width={200}
    height={75}
    position="relative"
    justifyContent="center"
    alignItems="center"
    m={{ base: '0 auto', md: 5 }}
  >
    {src && (
      <Image
        src={src}
        layout="fill"
        objectFit={objectFit}
        alt="Cut Into The Jamstack logo"
      />
    )}
    {children && children}
  </Box>
);

export const LogoSection = () => (
  <HomeSection id="section-technologies" shaded>
    <Heading size="lg" alignItems="center" mb={10}>
      Tech you&#39;ll use in the book:
    </Heading>
    <SimpleGrid columns={[1, 2, 3, 4]} spacing="20px">
      <LogoImage src="/react-logo.png" width={500} height={234} />
      <LogoImage src="/chakra-ui@2x.png" width={1996} height={514} />
      <LogoImage src="/nextjs-logo.png" width={800} height={479} />
      <LogoImage src="/stripe-logo.svg.webp" width={2560} height={1220} />
      <LogoImage src="/prisma-logo.png" width={568} height={320} />
      <LogoImage width={229} height={54}>
        <NextAuthLogo width="100%" height="100%" />
      </LogoImage>
      <LogoImage src="/react-hook-form-logo.png" width={1112} height={140} />
      <LogoImage src="/vercel-logo.png" width={640} height={233} />
    </SimpleGrid>
  </HomeSection>
);
