import { ReactNode } from 'react';
import { Box, ContainerProps } from '@chakra-ui/react';
import { MaxWidthContainer } from 'components/MaxWidthContainer';

export interface HomeSectionProps {
  children: ReactNode;
  shaded?: boolean;
  id: string;
}

export type Props = HomeSectionProps & ContainerProps;

export const HomeSection = ({
  children,
  shaded = false,
  id,
  ...rest
}: Props) => {
  return (
    <Box
      id={id}
      bg={shaded ? 'lightBeige' : 'transparent'}
      py={{ base: 50, md: 75 }}
      {...rest}
    >
      <MaxWidthContainer>{children}</MaxWidthContainer>
    </Box>
  );
};
