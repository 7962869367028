import {
  ConditionalScrollButton,
  ConditionalScrollButtonProps,
} from 'components/ConditionalScroll';

export type ActionButtonProps = ConditionalScrollButtonProps & {
  bg?: 'primary' | 'accent';
  children: React.ReactNode;
};

export function ActionButton(props: ActionButtonProps) {
  const { bg, children, ...rest } = props;
  const background = bg || 'primary';
  return (
    <ConditionalScrollButton
      bg={background}
      color={'whiteAlpha.900'}
      px={6}
      boxShadow="lg"
      _hover={{
        bg: background === 'primary' ? 'accent' : 'primary',
      }}
      {...rest}
    >
      {children}
    </ConditionalScrollButton>
  );
}
