import { useEffect } from 'react';
import { useRouter } from 'next/router';

import { Hero } from 'components/Hero';
import { Features } from 'components/Features';

import { NewsletterSignup } from 'components/NewsletterSignup';
import { Pricing } from 'components/Pricing';
import { Summary } from 'components/Summary';
import { LogoSection } from 'components/LogoSection';

export function Home() {
  const router = useRouter();
  useEffect(() => {
    router.prefetch('/toc');
    router.prefetch('/articles');
  }, []);

  return (
    <>
      <Hero />
      <LogoSection />
      <Summary />
      <Features />

      <Pricing />
      <NewsletterSignup />
    </>
  );
}

export default Home;
