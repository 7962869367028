import { ReactElement } from 'react';
import { Box, SimpleGrid, Icon, Text, Stack, Flex } from '@chakra-ui/react';
import { FcGraduationCap, FcSupport, FcStackOfPhotos } from 'react-icons/fc';
import { HomeSection } from 'components/HomeSection';

interface FeatureProps {
  title: string;
  text: string;
  icon: ReactElement;
}

const Feature = ({ title, text, icon }: FeatureProps) => {
  return (
    <Stack
      align={'center'}
      bg={'white'}
      boxShadow={'base'}
      py={6}
      px={4}
      rounded="lg"
    >
      <Flex
        w={16}
        h={16}
        align={'center'}
        justify={'center'}
        color={'white'}
        rounded={'full'}
        bg={'white'}
        mb={2}
        borderWidth={1}
        borderColor={'lightBeige'}
      >
        {icon}
      </Flex>
      <Text fontSize={21} fontWeight={600} color={'accent'}>
        {title}
      </Text>
      <Text fontSize={15} color={'gray.600'}>
        {text}
      </Text>
    </Stack>
  );
};

export function Features() {
  return (
    <HomeSection id={'section-features'} shaded>
      <Box py={4}>
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={7}>
          <Feature
            icon={<Icon as={FcGraduationCap} w={10} h={10} />}
            title={'Learn the Jamstack'}
            text={
              'The Jamstack (JavaScript, API and markup) produces FAST powerful websites that are fun to build.'
            }
          />
          <Feature
            icon={<Icon as={FcSupport} w={10} h={10} />}
            title={'Learn by Doing'}
            text={
              'The book teaches you how I built JamShots, a collaborative photo gallery application, step-by-step with every line of code broken down.'
            }
          />
          <Feature
            icon={<Icon as={FcStackOfPhotos} w={10} h={10} />}
            title={'Full-Stack JavaScript'}
            text={
              "You'll build JamShots with some of the hottest JavaScript frameworks and libraries around. "
            }
          />
        </SimpleGrid>
      </Box>
    </HomeSection>
  );
}
