import { Avatar, Box, Heading, Link, Stack, Text } from '@chakra-ui/react';
import { HomeSection } from 'components/HomeSection';

export function Summary() {
  return (
    <HomeSection id={'about'}>
      <Stack
        py={16}
        px={8}
        spacing={{ base: 8, md: 10 }}
        align={'center'}
        direction={'column'}
        id="section-summary"
      >
        <Box textAlign={'center'}>
          <Heading size="xl">Why a Jamstack SaaS book?</Heading>
        </Box>
        <Box
          fontSize={{ base: 'xl', md: '2xl' }}
          textAlign={'center'}
          maxW={'3xl'}
        >
          <Text mb={5}>
            Hey, I&#39;m{' '}
            <Link
              href="https://mikecavaliere.com/"
              target="_blank"
              fontWeight="bold"
              textDecoration="underline"
            >
              Mike Cavaliere
            </Link>
            . I&#39;m a Senior Software Engineer for an agency called{' '}
            <Link
              href="https://echobind.com/"
              target="_blank"
              fontWeight="bold"
              textDecoration="underline"
            >
              Echobind
            </Link>
            . I&#39;ve been writing code for two decades, and have been using
            JavaScript the whole time.
          </Text>

          <Text mb={5}>
            I absolutely love the Jamstack (JavaScript, APIs and markup) way of
            building things. But JavaScript evolves fast, and there are a ton of
            tools to choose from.
          </Text>

          <Text mb={5}>
            What form library should I use? How should I style things? Where do
            I put all my data? It&#39;s kind of overwhelming. 🤯
          </Text>

          <Text mb={5}>
            When you&#39;re building a SaaS (software-as-a-service) app, there
            are new challenges.
          </Text>

          <Text mb={5}>
            Do I need a database? Where should I host it? How do I keep my DB in
            sync with API data? Double 🤯🤯
          </Text>

          <Text mb={5}>
            In <i>Cut Into The Jamstack</i>, I answer all of these questions and
            more. I&#39;ll walk you through how I&#39;m building a SaaS app,
            line by line with complete source code.
          </Text>

          <Text mb={5}>
            You&#39;ll get a taste of all of the popular libraries below and see
            how they work together to build a working, usable app.
          </Text>

          <Text mb={5}>
            After reading <i>Cut Into The Jamstack</i>, you&#39;ll feel much
            more comfortable kicking off your own Jamstack SaaS projects with
            all of these tools. I look forward to having you read it!
          </Text>
        </Box>
        <Box textAlign={'center'}>
          <Avatar
            src={'/mike.jpg'}
            mb={2}
            size="2xl"
            name="Photo of Mike Cavaliere, Senior Software Engineer and author of Cut Into the Jamstack"
            width={128}
            height={128}
          />

          <Text fontWeight={600}>Mike Cavaliere</Text>
          <Text fontSize={'sm'}>Senior Software Engineer</Text>
          <Text fontSize={'sm'}>Echobind</Text>
        </Box>
      </Stack>
    </HomeSection>
  );
}
